@import url('https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@400;700&display=swap');

@keyframes animate {
  0%, 10%, 100% {
    width: 0px;
  }
  70%, 90% {
    width: 100%;
  }
}

@keyframes blink {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

.text-10x {
  font-family: 'Anonymous Pro', monospace;
}

.textEffect {
  position: relative;
  color: transparent;
  -webkit-text-stroke: 1px #f5f4f0;
  line-height: 1.2em;
  padding: 2px 5px;
}

.textEffect::before {
  content: attr(data-text);
  position: absolute;
  width: 0;
  height: 100%;
  top: -2px;
  left: -2px;
  color: #f6f4f0;
  border: 2px solid #eeece7;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 2px 5px;
  white-space: nowrap;
  overflow: hidden;
  animation: animate 3s linear infinite;
  filter: drop-shadow(0 0 5px #f8f1de) drop-shadow(0 0 25px #fcf6e6);
}

.logo .blink {
  animation: blink 4s step-start infinite;
  font-family: 'Anonymous Pro', monospace;
}

@media (min-width: 1024px) {
  .lg\:flex-col {
    flex-direction: column !important;
  }

  .lg\:gap-4 {
    gap: 1rem !important;
  }

  .lg\:text-center {
    text-align: center !important;
  }

  .lg\:pr-4 {
    padding-right: 0 !important;
  }

  .lg\:pl-4 {
    padding-left: 0 !important;
  }

  .lg\:mt-8 {
    margin-top: 2rem !important;
  }
}
